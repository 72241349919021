<template>
    <IFrame v-if="currentSrc" ref="iFrameRef" :src="currentSrc" :enabled="enabled"
        :syncContexts="syncContexts" :useLoadingMessage="useLoadingMessage" @load="handleLoad" />
</template>
<script setup lang="ts">
import { ref, watch } from 'vue';
import IFrame from './Iframes.Iframe.vue';
import { useEventListener } from 'o365-vue-utils';


const props = withDefaults(defineProps<{
    src?: string
    enabled?: boolean,
    syncContexts?: boolean,
    preserveQueryParams?: boolean,
    useLoadingMessage?: boolean,
}>(), {
    enabled: undefined,
    syncContexts: undefined,
});

const emit = defineEmits<{
    (e: 'load', event: Event, currentSrc: string|null): void
}>();
const currentSrc = ref<string|undefined>(undefined);
const iFrameRef = ref<IFrame | null>(null);

function handleLoad(pEvent: Event, pSrc: string|null) {

    emit('load', pEvent, pSrc);
}

if (props.useLoadingMessage || true) {
    useEventListener(window, 'message', (pEvent) => {
        const data = pEvent.data;
        if (iFrameRef.value == null || data == null || typeof data !== 'object' || data.type !== 'o365-system' || data.action !== 'app-created') {
            return;
        }
        if (iFrameRef.value.contentWindow != pEvent.source) {
             return; }
    })
}

let validSrcSet = false;
function validateAndSetSrc(pSrc?: string, pForceSet = false) {
    if (pSrc != null && !pSrc.includes('undefined')) {
        if (validSrcSet && !iFrameRef?.value?.isLoading) { 
            const currentPath = currentSrc.value?.split('?')[0];
            const newPath = pSrc.split('?')[0];
            if (currentPath == newPath && !pForceSet) {
                return;
            }
        }
        currentSrc.value = pSrc;
        validSrcSet = true;
    } else if (validSrcSet) {
        currentSrc.value = undefined;
        validSrcSet = false;
    }
}
validateAndSetSrc(props.src);

const post = (...args) => {
    iFrameRef?.value?.post(...args);
}

watch(() => props.enabled, (isEnabled) => {
    if (isEnabled && props.src) {
        if (props.src != currentSrc.value) {
            currentSrc.value = undefined; // Clear the current src since in most cases messsage listeners do not exist here
        }
        handleSrcChange(props.src, currentSrc.value);
    }
});

watch(() => props.src, (newVal, oldVal) => {
    handleSrcChange(newVal, oldVal);
});

function handleSrcChange(pNewSrc?: string, pOdlSrc?: string) {
        if (iFrameRef.value && validSrcSet && iFrameRef.value.iframeRef && iFrameRef.value.iframeRef.src && iFrameRef.value.iframeRef.contentWindow) {
        const oldUrlParts = pNewSrc?.split('?');
        if (props.preserveQueryParams && oldUrlParts && oldUrlParts[1] && pOdlSrc) {
            try {
                const oldParams = new URLSearchParams(iFrameRef.value.iframeRef.contentWindow.location.search);
                const newParams = new URLSearchParams('?'+oldUrlParts[1]);
                oldParams.forEach((value, key) => {
                    if (newParams.get(key) == null) {
                        newParams.set(key, value);
                    }
                });
                pNewSrc = decodeURIComponent(oldUrlParts[0]+'?'+newParams.toString())
            } catch (ex) {
                console.warn(ex);
            }
        }
        try {
            iFrameRef.value.iframeRef.contentWindow.history.pushState(pOdlSrc, '', pNewSrc);
        } catch (ex) {
            console.warn(ex);
        }
    }
    validateAndSetSrc(pNewSrc);
}

defineExpose({ iFrameRef, post, validateAndSetSrc });
</script>